@import "../css/variables.module";

.excalidraw {
  .ExportDialog__preview {
    --preview-padding: calc(var(--space-factor) * 4);

    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMUlEQVQ4T2NkYGAQYcAP3uCTZhw1gGGYhAGBZIA/nYDCgBDAm9BGDWAAJyRCgLaBCAAgXwixzAS0pgAAAABJRU5ErkJggg==")
      left center;
    text-align: center;
    padding: var(--preview-padding);
    margin-bottom: calc(var(--space-factor) * 3);
  }

  .ExportDialog__preview canvas {
    max-width: calc(100% - var(--preview-padding) * 2);
    max-height: 25rem;
  }

  &.theme--dark .ExportDialog__preview canvas {
    filter: none;
  }

  .ExportDialog__actions {
    width: 100%;
    display: flex;
    grid-gap: calc(var(--space-factor) * 2);
    align-items: top;
    justify-content: space-between;
  }

  .ExportDialog__name {
    grid-column: project-name;
    margin: auto;
    display: flex;
    align-items: center;

    .TextInput {
      height: calc(1rem - 3px);
      width: 200px;
      overflow: hidden;
      text-align: center;
      margin-left: 8px;
      text-overflow: ellipsis;

      &--readonly {
        background: none;
        border: none;
        &:hover {
          background: none;
        }
        width: auto;
        max-width: 200px;
        padding-left: 2px;
      }
    }
  }

  @include isMobile {
    .ExportDialog {
      display: flex;
      flex-direction: column;
    }

    .ExportDialog__actions {
      flex-direction: column;
      align-items: center;
    }

    .ExportDialog__actions > * {
      margin-bottom: calc(var(--space-factor) * 3);
    }

    .ExportDialog__preview canvas {
      max-height: 30vh;
    }

    .ExportDialog__dialog,
    .ExportDialog__dialog .Island {
      height: 100%;
      box-sizing: border-box;
    }

    .ExportDialog__dialog .Island {
      overflow-y: auto;
    }
  }
}
